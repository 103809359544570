import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"

// lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

// alice carousel
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Pagination = props => (
  <div className="pagination -post">
    <ul>
      {props.previous && (
        <li>
          <Link to={props.previous.frontmatter.slug} rel="prev">
            <p>
              <span className="icon -left">
                <RiArrowLeftLine />
              </span>{" "}
              Previous
            </p>
            <span className="page-title">
              {props.previous.frontmatter.title}
            </span>
          </Link>
        </li>
      )}
      {props.next && (
        <li>
          <Link to={props.next.frontmatter.slug} rel="next">
            <p>
              Next{" "}
              <span className="icon -right">
                <RiArrowRightLine />
              </span>
            </p>
            <span className="page-title">{props.next.frontmatter.title}</span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

const handleDragStart = e => e.preventDefault()

const getGalleryImageList = images => {
  return images.map(img => {
    return img.childImageSharp.full.src
  })
}

const Post = ({ data, pageContext }) => {
  const [lbIndex, setlbIndex] = useState(0)
  const [lbOpen, setlbOpen] = useState(false)
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : ""
  const { previous, next } = pageContext
  const galleryImgs = frontmatter.scrollImages
    ? getGalleryImageList(frontmatter.scrollImages)
    : []

  const getImgThumbnailList = images => {
    return images.map((img, i) => {
      return (
        <img
          key={img.id}
          style={{
            width: "160px",
            height: "150px",
            paddingTop: "10px:",
            paddingRight: "5px",
            cursor: "pointer",
          }}
          src={img.childImageSharp.thumb.src}
          onDragStart={handleDragStart}
          onClick={() => {
            setlbIndex(i)
            setlbOpen(true)
          }}
        />
      )
    })
  }
  const imgThumbList = frontmatter.scrollImages
    ? getImgThumbnailList(frontmatter.scrollImages)
    : []

  let props = {
    previous,
    next,
  }

  return (
    <Layout className="page">
      <SEO
        title={frontmatter.pageTitle}
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        image={Image.src ? Image.src : ""}
        article={true}
      />
      <article className="blog-post">
        <header className="featured-banner">
          <section className="article-header">
            <h1>{frontmatter.title}</h1>
            <time>{frontmatter.date}</time>
          </section>
          {Image ? (
            <div style={{ margin: "0 auto", maxWidth: 800 }}>
              <Img
                fluid={Image}
                backgroundColor={true}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={frontmatter.title + " - Featured image"}
                className="featured-image"
              />
            </div>
          ) : (
            ""
          )}
        </header>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {frontmatter.scrollImages && (
          <div style={{ width: "70%", margin: "0 auto", marginTop: "20px" }}>
            <AliceCarousel disableButtonsControls autoWidth>
              {imgThumbList}
            </AliceCarousel>
          </div>
        )}
      </article>
      {(previous || next) && <Pagination {...props} />}
      {lbOpen && (
        <Lightbox
          mainSrc={galleryImgs[lbIndex]}
          nextSrc={galleryImgs[(lbIndex + 1) % galleryImgs.length]}
          prevSrc={
            galleryImgs[(lbIndex + galleryImgs.length - 1) % galleryImgs.length]
          }
          onCloseRequest={() => setlbOpen(false)}
          onMovePrevRequest={() => {
            const newIdx =
              (lbIndex + galleryImgs.length - 1) % galleryImgs.length
            setlbIndex(newIdx)
          }}
          onMoveNextRequest={() => {
            const newIdx = (lbIndex + 1) % galleryImgs.length
            setlbIndex(newIdx)
          }}
        />
      )}
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query CasePostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        pageTitle
        description
        featuredImage {
          childImageSharp {
            fluid(
              fit: CONTAIN
              maxWidth: 1080
              maxHeight: 548
              quality: 80
              srcSetBreakpoints: [350, 700, 1050, 1400]
            ) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        scrollImages {
          id
          childImageSharp {
            thumb: fluid(maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            full: resize(height: 600, quality: 80) {
              src
            }
          }
        }
      }
    }
  }
`
